@import 'src/styles/base/main';
@import 'src/styles/base/background-color';
@import 'src/styles/base/border';
@import 'src/styles/base/gutter';
@import 'src/styles/common/display';

.container {
  position: relative;
}

.input {
  color: var(--text-primary);
  height: 50px;
  padding: 12px 14px;
  border-radius: var(--border-radius-input);
  border: var(--border-solid-divider);
  font-size: inherit;
  background-color: var(--background-color-paper);
  @include xs {
    height: 44px;
  }

  &:focus {
    outline: none;
    border: 2px solid var(--color-primary-main);
  }

  &--icon {
    padding-right: 40px !important;
  }
  &--label {
    margin-top: 6px;
  }
  &--error {
    //border-color: var(--color-error-main);
  }

  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;

  color: var(--text-primary);
  &--primary {
    color: var(--color-primary-main);
  }

  &--gutterBottom {
    margin-bottom: 6px;
  }
}

.require {
  display: inline;
  color: var(--color-error-main);
  font-weight: var(--font-weight-bold);
  margin-left: var(--gutter-component);
}

.icon {
  position: absolute;
  right: var(--gutter-component);
  top: 30px;
}

.error {
  margin-top: var(--gutter-component);

  color: var(--color-error-main);
}
