@import 'src/styles/common/display';

.container {
  @extend .display-column;
  @extend .justify-content-center;
  @extend .align-items-center;
  height: 100%;
  width: 100%;
  flex: 1;
}

.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3fc9ab; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

.message {
  margin-top: 1.25em !important;
  color: var(--text-secondary) !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
