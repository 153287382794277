@import '../base/palette.scss';
@import '../base/background-color.scss';

a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: var(--background-color) !important;
}

input,
textarea {
  font-size: var(--font-size);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid transparent;
  -webkit-text-fill-color: var(--text-primary);
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 1000px #00000000 inset;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

##{$app-id}root {
  width: 100%;
  height: 100%;
}

.scrollbar,
.ReactVirtualized__List {
  overflow: auto;
}

.scrollbar::-webkit-scrollbar,
.ReactVirtualized__List::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollbar::-webkit-scrollbar-thumb,
.ReactVirtualized__List::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 4px;
}

.scrollbar:hover::-webkit-scrollbar,
.ReactVirtualized__List:hover::-webkit-scrollbar {
  background-color: $background-color-lv4;
  border-radius: 4px;
}

.my-popover-paper {
  margin-top: 5px;
}

.my-loading {
  position: absolute;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: #00000030;
}
