%full-width-height {
  width: 100%;
  height: 100%;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.display-column {
  display: flex;
  flex-direction: column;
}

%display-column {
  display: flex;
  flex-direction: column;
}

%display-row {
  display: flex;
  flex-direction: row;
}

%justify-flex-end {
  justify-content: flex-end;
}

.display-row {
  display: flex;
  flex-direction: row;
}

%display-row {
  display: flex;
  flex-direction: row;
}

.justify-content-center {
  justify-content: center;
}

%justify-content-center {
  justify-content: center;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}
%align-items-center {
  align-items: center;
}
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

$screen-xs-max: 599px;
$screen-sm-max: 959px;
$screen-md-max: 1279px;
$screen-lg-max: 1919px;
$screen-xl-max: 5000px;

// media devices
@mixin xs {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// media lt queries
@mixin lt-sm {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// media gt queries
@mixin gt-xs {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
